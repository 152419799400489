import Script from "next/script";
import type { Graph, Product, Store, WithContext } from 'schema-dts';

interface Props {
    schema: WithContext<Product> | WithContext<Store> | Graph
    id: string;
}

const Schema = ({ schema, id }: Props) => {
    return (
        <>
            <Script
                id={`${schema['@type'] || 'graph'}-${id}`}
                type="application/ld+json"
                dangerouslySetInnerHTML={{  __html: JSON.stringify(schema) }}
            />
            {process.env.NEXT_PUBLIC_DEBUG && <pre>{JSON.stringify(schema, null, 2)}</pre>}
        </>
    )
}

export default Schema